import { createSlice } from '@reduxjs/toolkit';
import { IViewCats } from '../../types/types';
import { getViewCats } from './catsAPI';

interface ICatsSlice {
   status: 'idle' | 'loading' | 'succeeded' | 'failed';
   data: IViewCats[];
   error: string | null;
}

const initialState: ICatsSlice = {
   status: 'idle',
   data: [],
   error: null
}

const catsSlice = createSlice({
   name: 'cats',
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(getViewCats.pending, (state) => {
            state.status = 'loading';
            state.data = [];
            state.error = null;
         })
         .addCase(getViewCats.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload.data;
            state.error = null;
         })
         .addCase(getViewCats.rejected, (state, action) => {
            state.status = 'failed';
            state.data = [];
            state.error = action.error.message || 'An error occurred';
         });
   }
});

export default catsSlice.reducer;

// No actions to export in this case
// export const {} = catsSlice.actions;