import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { LINKS } from '../types/types';
import { signOut } from '../features/auth/authAPI';
import { store } from './store';
import { resetAuth } from '../features/auth/authSlice';

// Define the structure of your refresh token response
interface RefreshTokenResponse {
    accessToken: string;
}

const BASE_URL = 'https://sub.xn--y9ap3dco.xn--y9a3aq/'; // Adjust this to your API base URL

const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        if (!error.config) {
            return Promise.reject(error);
        }

        const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const refreshResponse = await axios.get<RefreshTokenResponse>(LINKS.TOKEN, {
                    withCredentials: true, // If you're using HTTP-only cookies for refresh token
                });
                const newToken = refreshResponse.data.accessToken;
                localStorage.setItem('auth_token', newToken);

                // Update the original request with the new token
                if (originalRequest.headers) {
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                }

                // Retry the original request with the new token
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                // Handle refresh token failure (e.g., logout user, redirect to login)
                console.error('Failed to refresh token:', refreshError);

                await logoutUser();

                // You might want to dispatch a logout action or redirect to login page here
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

// Add this function at the end of the file
export const logoutUser = async () => {
    try {
        // Clear local storage
        localStorage.removeItem('auth_token');

        // Dispatch the logout action
        store.dispatch(resetAuth());

        // Optionally, redirect to login page
        // window.location.href = '/login';
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

export default axiosInstance;