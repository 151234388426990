import React, { useState } from "react";
import st from "./register.module.scss";
import { useForm } from "react-hook-form";
import { LINKS, RegForm } from "../../../types/types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import toast from "react-hot-toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { registerUser } from "../../../features/auth/authAPI";
import { useNavigate } from "react-router-dom";

// Validation Schema
const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  username: yup
    .string()
    .required("Username is required")
    .min(5, "Username must be at least 5 characters long")
    .max(30, "Username cannot be more than 30 characters long"),
  firstname: yup
    .string()
    .required("Name is required")
    .matches(/^[a-zA-Zա-ֆԱ-Ֆ]+$/, "Name can only contain letters")
    .min(2, "Name must be at least 2 characters long")
    .max(30, "Name cannot be more than 30 characters long"),
  surname: yup
    .string()
    .required("Surname is required")
    .matches(/^[a-zA-Zա-ֆԱ-Ֆ]+$/, "Surname can only contain letters")
    .min(4, "Surname must be at least 4 characters long")
    .max(50, "Surname cannot be more than 50 characters long"),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[0-9])(?=.*[a-zա-ֆ])(?=.*[A-ZԱ-Ֆ]).{8,32}$/,
      "Password must be at least 8 characters long, start with a letter, contain at least 1 number, 1 lowercase letter, and 1 uppercase letter"
    ),
  password_confirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const Register: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isPassShow, setIsPassShow] = useState<boolean>(false);
  const [isChPassShow, setIsChPassShow] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RegForm>({
    resolver: yupResolver(schema),
  });

  const { status } = useAppSelector((state) => state.auth);

  const save = (data: RegForm) => {
    const regData = {
      email: data.email,
      username: data.username,
      password: data.password,
      name: data.firstname,
      surname: data.surname,
      password_confirmation: data.password_confirmation,
    };

    dispatch(registerUser(JSON.stringify(regData)))
      .unwrap()
      .then(() => {
        toast.success(`Registration Successful. Please login`);
        reset();
        navigate("/sign-in");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className={st.container}>
      <h3 className={st.title}>Register</h3>
      <p className={st.text__req}>* fields are required</p>

      <form className={st.form} onSubmit={handleSubmit(save)}>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="email">
            E-mail<small style={{ color: "red" }}>*</small>
          </label>
          {errors.email && (
            <p className={st.form__error_text}>{errors.email.message}</p>
          )}
          <input
            type="text"
            id="email"
            className={st.form__input}
            {...register("email")}
          />
        </div>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="username">
            Username<small style={{ color: "red" }}>*</small>
          </label>
          {errors.username && (
            <p className={st.form__error_text}>{errors.username.message}</p>
          )}
          <input
            type="text"
            id="username"
            className={st.form__input}
            {...register("username")}
          />
        </div>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="name">
            Name<small style={{ color: "red" }}>*</small>
          </label>
          {errors.firstname && (
            <p className={st.form__error_text}>{errors.firstname.message}</p>
          )}
          <input
            type="text"
            id="firstname"
            className={st.form__input}
            {...register("firstname")}
          />
        </div>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="surname">
            Surname<small style={{ color: "red" }}>*</small>
          </label>
          {errors.surname && (
            <p className={st.form__error_text}>{errors.surname.message}</p>
          )}
          <input
            type="text"
            id="surname"
            className={st.form__input}
            {...register("surname")}
          />
        </div>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="password">
            Password<small style={{ color: "red" }}>*</small>
          </label>
          {errors.password && (
            <p className={st.form__error_text}>{errors.password.message}</p>
          )}
          <div className={st.form__inp_box}>
            <input
              type={!isPassShow ? "password" : "text"}
              id="password"
              className={st.form__input}
              {...register("password")}
            />
            {!isPassShow ? (
              <i
                className="fa-solid fa-eye"
                onClick={() => setIsPassShow(true)}
              ></i>
            ) : (
              <i
                className="fa-solid fa-eye-slash"
                onClick={() => setIsPassShow(false)}
              ></i>
            )}
          </div>
        </div>
        <div className={st.form__lab_inp_container}>
          <label htmlFor="chpassword">
            Repeat password<small style={{ color: "red" }}>*</small>
          </label>
          {errors.password_confirmation && (
            <p className={st.form__error_text}>
              {errors.password_confirmation.message}
            </p>
          )}
          <div className={st.form__inp_box}>
            <input
              type={!isChPassShow ? "password" : "text"}
              id="chpassword"
              className={st.form__input}
              {...register("password_confirmation")}
            />
            {!isChPassShow ? (
              <i
                className="fa-solid fa-eye"
                onClick={() => setIsChPassShow(true)}
              ></i>
            ) : (
              <i
                className="fa-solid fa-eye-slash"
                onClick={() => setIsChPassShow(false)}
              ></i>
            )}
          </div>
        </div>
        <button className={st.form__button}>
          {status === "loading" ? "Registration..." : "Register"}
        </button>
      </form>
    </div>
  );
};

export default Register;
