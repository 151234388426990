import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signIn, registerUser, signOut, ILoginResponse, getUser } from './authAPI';
import { IUser } from '../../types/types';
import { stat } from 'fs';

interface AuthState {
   user: IUser | null;
   status: 'idle' | 'loading' | 'succeeded' | 'failed';
   error: string | null;
}

const initialState: AuthState = {
   user: null,
   status: 'idle',
   error: null,
};


const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
      resetAuth: (state) => {
         state.user = initialState.user;
         state.status = initialState.status;
         state.error = initialState.error;
      },
   },
   extraReducers: (builder) => {
      builder

         // login
         .addCase(signIn.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(signIn.fulfilled, (state, action: PayloadAction<ILoginResponse>) => {
            state.status = 'succeeded';
            state.user = action.payload.data.user;
         })
         .addCase(signIn.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Something went wrong';
         })

         // logout
         .addCase(signOut.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(signOut.fulfilled, (state) => {
            state.status = 'succeeded';
            state.user = null;
            localStorage.removeItem("auth_token");
         })
         .addCase(signOut.rejected, (state, action) => {
            state.status = 'failed';
            state.user = null;
            state.error = action.error.message || 'Something went wrong';
         })

         // register
         .addCase(registerUser.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(registerUser.fulfilled, (state) => {
            state.status = 'succeeded';
         })
         .addCase(registerUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Something went wrong';
         })

         // getUser
         .addCase(getUser.pending, (state) => {
            state.status = 'loading';
         })
         .addCase(getUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.user = action.payload.data.user;
         })
         .addCase(getUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Something went wrong';
         })
   }
})

export default authSlice.reducer
export const { resetAuth } = authSlice.actions