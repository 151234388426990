import React, { useState } from "react";
import styles from "./signin.module.scss";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useForm } from "react-hook-form";
import { SigninForm } from "../../../types/types";
import { Navigate } from "react-router-dom";
import { signIn } from "../../../features/auth/authAPI";
import toast from "react-hot-toast";

const Signin: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SigninForm>();
  const { user, error, status } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [isShowPass, setIsShowPass] = useState<boolean>(false);

  const save = (data: SigninForm): void => {
    const signInData = {
      email: data.email,
      password: data.password,
      remember_me: data.remember ?? false,
    };

    dispatch(signIn(JSON.stringify(signInData)))
      .unwrap()
      .then((user) => {
        toast.success(`Welcome, ${user.data.user.username}!`);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div className={styles.container}>
      {!user ? (
        <>
          <h3 className={styles.title}>Sign In</h3>
          <form className={styles.form} onSubmit={handleSubmit(save)}>
            <div className={styles.form__lab_inp_container}>
              <label htmlFor="email">
                <b>E-mail</b>
              </label>
              {errors.email && (
                <p className={styles.form__error_text}>Invalid email address</p>
              )}
              <input
                type="text"
                id="email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
              />
            </div>
            <div className={styles.form__lab_inp_container}>
              <label htmlFor="password">
                <b>Password</b>
              </label>
              {errors.password && (
                <p className={styles.form__error_text}>Invalid password</p>
              )}
              <div className={styles.form__input_box}>
                <input
                  type={!isShowPass ? "password" : "text"}
                  id="password"
                  {...register("password", {
                    required: true,
                    //   pattern: /^(?=.*[0-9])(?=.*[a-zա-ֆ])(?=.*[A-ZԱ-Ֆ]).{8,32}$/,
                  })}
                />
                <div className={styles.form__icon_box}>
                  {!isShowPass ? (
                    <i
                      className="fa-solid fa-eye"
                      onClick={() => setIsShowPass(true)}
                    ></i>
                  ) : (
                    <i
                      className="fa-solid fa-eye-slash"
                      onClick={() => setIsShowPass(false)}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.form__lab_check_container}>
              <input
                type="checkbox"
                id="rem"
                {...register("remember")}
                className={styles.form__check}
              />
              <label htmlFor="rem" className={styles.form__lab_check}>
                Remember me
              </label>
            </div>
            <button className={styles.form__button}>
              {status === "loading" ? "Sign In..." : "Sign In"}
            </button>
          </form>
        </>
      ) : (
        <Navigate to={"/profile"} />
      )}
    </div>
  );
};

export default Signin;
