import { createAsyncThunk } from '@reduxjs/toolkit';
import { IHeaders, LINKS } from '../../types/types';
import axiosInstance from '../../app/api';

const headers: IHeaders = {
   "Content-Type": "text/json"
};

// Interface for the add cat response
interface IAddCatResponse {
   // The response is empty with a status code of 204
}

// Interface for a single category item
interface ICategoryItem {
   user_cat_id: number;
   cat: number;
}

// Interface for the view cats response
interface IViewCatsResponse {
   status: boolean;
   data: ICategoryItem[];
}

export const addCat = createAsyncThunk<IAddCatResponse, string>(
   'cats/add',
   async (obj: string, thunkAPI) => {
      return await axiosInstance.post<IAddCatResponse>(LINKS.ADDCAT, obj, { headers })
         .then((response) => {
            return response.data;
         })
         .catch((err) => {
            return thunkAPI.rejectWithValue(err.response.data);
         });
   }
);

export const getViewCats = createAsyncThunk<IViewCatsResponse, number | string>(
   'cats/view',
   async (cat_id: number | string, thunkAPI) => {
      return await axiosInstance.get<IViewCatsResponse>(`${LINKS.VIEWCATS}/${cat_id}`, { headers })
         .then((response) => {
            return response.data;
         })
         .catch((err) => {
            return thunkAPI.rejectWithValue(err.response.data);
         });
   }
);