import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getViewCats } from "../../../../features/cats/catsAPI";
import { Link, Navigate, useParams } from "react-router-dom";
import st from "./usercatview.module.scss";

const UserViewCat: React.FC = () => {
  const { uid } = useParams();
  const dispatch = useAppDispatch();
  const { data, status, error } = useAppSelector((state) => state.catsStore);
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (!uid) return;
    dispatch(getViewCats(uid));
  }, [uid]);

  if ((uid && Number(uid) !== user?.id) || !user) {
    return <Navigate to={"/"} />;
  } else {
    return (
      <section>
        <h3 className={st.title}>View Category</h3>
        {status === "loading" ? (
          <p className={st.text__err}>Loading...</p>
        ) : error ? (
          <p className={st.text__err}>
            Connection problem. Try refreshing the page
          </p>
        ) : data && data.length === 0 ? (
          <p className={st.text__not}>No categories added</p>
        ) : (
          data &&
          data.length > 0 && (
            <ol className={st.menu}>
              {data.map((d) => (
                <li key={d.user_cat_id} className={st.menu__list}>
                  {d.cat === 1
                    ? "Astronomy"
                    : d.cat === 2
                    ? "Astrophysics"
                    : "Cosmology"}
                </li>
              ))}
            </ol>
          )
        )}
      </section>
    );
  }
};

export default UserViewCat;
