import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { Toaster } from "react-hot-toast";
import { useAppDispatch } from "./app/hooks";
import { getUser } from "./features/auth/authAPI";

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
      <Toaster />
    </BrowserRouter>
  );
};

export default App;
