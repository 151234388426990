import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IHeaders, IUser, LINKS } from '../../types/types';
import axiosInstance from '../../app/api';

const headers: IHeaders = {
   "Content-Type": "application/json",
   "Accept": "application/json",
};

export interface ILoginResponse {
   status: boolean;
   data: {
      accessToken: string;
      user: IUser;
   };
}

export interface UserResponse {
   status: boolean;
   data: {
      user: IUser;
   };
}

export interface IRegisterResponse {
   status: boolean;
   data: IUser;
}

export const signIn = createAsyncThunk<ILoginResponse, string>(
   'auth/login',
   async (obj: string, thunkAPI) => {
      return await axiosInstance.post<ILoginResponse>(LINKS.SIGNIN, obj, { headers })
         .then((response) => {
            if (response.data.data.accessToken) {
               localStorage.setItem("auth_token", response.data.data.accessToken);
            }
            return response.data;
         })
         .catch((err) => {
            return thunkAPI.rejectWithValue(err.response.data);
         });
   }
);

export const getUser = createAsyncThunk<UserResponse>(
   'api/user',
   async (_, thunkAPI) => {
      return await axiosInstance.get<UserResponse>(LINKS.GET_USER, { headers })
         .then((response) => {
            return response.data;
         })
         .catch((err) => {
            return thunkAPI.rejectWithValue(err.response.data);
         });
   }
);

export const registerUser = createAsyncThunk<IRegisterResponse, string>(
   'auth/register',
   async (obj: string, thunkAPI) => {
      return await axiosInstance.post<IRegisterResponse>(LINKS.REGISTER, obj, { headers })
         .then((response) => {
            return response.data;
         })
         .catch((err) => {
            return thunkAPI.rejectWithValue(err.response.data);
         });
   }
);

export const signOut = createAsyncThunk(
   'auth/logout',
   async (_, thunkAPI) => {
      const token = localStorage.getItem("auth_token");
      if (!token) {
         return thunkAPI.rejectWithValue({ status: false, message: 'No authentication token found' });
      }

      return await axiosInstance.delete(LINKS.LOGOUT, {
         headers: {
            ...headers,
            Authorization: `Bearer ${token}`
         }
      })
         .then(() => {
            localStorage.removeItem("auth_token");
         })
         .catch((err) => {
            console.log(err)
            // If there's no token or the request fails, still remove the token
            // localStorage.removeItem("auth_token");

            if (axios.isAxiosError(err) && err.response) {
               return thunkAPI.rejectWithValue(err.response.data);
            }
            return thunkAPI.rejectWithValue({ status: false, message: 'An error occurred during sign out' });

         });
   }
);